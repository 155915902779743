import React from 'react'
import style from './heroBanner.module.css'

const Hero = ({ title, align = 'left' }) => {
  return (
    <div className={style.heroBanner}>
      <h1 style={{ textAlign: align }}>
        {title}
      </h1>
    </div>
  )
}

export default Hero
